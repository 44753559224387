import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './Home'
import Register from './Companents/Register/Register'

export default function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/thankyou' element={<Register/>}/>
      </Routes>
    </div>
  )
}
