import React, { useState } from "react";
import "./Div.css";
import star from "../../Asists/img/06.png";
import file from "../../Asists/img/domain.png";
import PhoneInput from "react-phone-input-2";
import foto_absalute from "../../Asists/img/foto.png";
import fintech from "../../Asists/img/fintechhub.svg";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
export default function Div() {
  const [num, setNum] = useState("");
  const [num1, setNum1] = useState("");
  const [name, setName] = useState("");
  const [familya, setFamilya] = useState("");
  const navigate = useNavigate();
  const handleregister = async (e) => {
    e.preventDefault();
    const data = {
      familiya : 'fintechhub' , 
      desc: familya,
      ism: name,
      kurs: "frontend",
      phone: num,
      phone_2: num1,
      target: 4,
    };
    try {
      const response = await axios
        .post("https://form-api.fintechhub.uz/api/register/", data)
        .then((data) => {
          //   setPopUp(true);
          //   setResponse(true);
          setName("");
          setNum("");
          setNum1("");
          navigate("/thankyou");
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <div className="boss">
        <div className="first">
          <img className="fintech" src={fintech} alt="" />
          <div className="box-top-left">
            {/* <img src={foto} alt="" /> */}
            <i class="fa-solid fa-graduation-cap"></i>
            <h1 className="text-top-left">
              Dasturlashni <br />
              o'rganmoqchimisiz?
            </h1>
          </div>
          <div className="center-left">
            <h1 className="center-text">Unda bizni eshiting:</h1>
            <div className="list">
              <p className="left-text">
                <img src={star} alt="" />
                Senior darajadagi ustozlar
              </p>
              <p className="left-text">
                <img src={star} alt="" />
                Amaliy ko'nikmalar
              </p>
              <p className="left-text">
                <img src={star} alt="" />
                Pullik Real loyihalarda tajriba to'plash
              </p>
              <p className="left-text">
                <img src={star} alt="" />
                Startup loyihangizga investitsiya kiritish imkoniyati
              </p>
              <p className="left-text">
                <img src={star} alt="" />
                Zamonaviy dasturlash atmosferasi
              </p>
            </div>
          </div>
          <div className="left-bottom">
            <img src={file} alt="" />
            <p className="bottom-p">
              Professional dasturchi bo'lmoqchi bo'lsangiz hoziroq
              <br /> ro‘yxatdan o‘ting!
            </p>
          </div>
        </div>
        <div className="box">
          <p className="location">
            <i class="fa-solid fa-location-dot"></i>
            Toshkent
          </p>
          <div className="second">
            <h1 className="right-top">
              Dasturlashga ilk qadam <br />
              <span>FinTechHub</span>
              dan boshlanadi!
            </h1>
            <p className="right">
              Bepul maslahat olish uchun ro'yhatdan o'ting
            </p>
            <form action="">
              <p className="form">Ismingiz?</p>
              <input
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder="Ismingiz"
                required
              />
              <div className="phone">
                <p className="form">Telefon raqamingiz?</p>{" "}
                <PhoneInput
                  country={"uz"}
                  value={num}
                  onChange={(phone) => setNum(phone)}
                />
              </div>
              <div className="phone1">
                <p className="form">Qo`shimcha telefon raqamingiz?</p>{" "}
                <PhoneInput
                  country={"uz"}
                  value={num1}
                  onChange={(phone) => setNum1(phone)}
                />
              </div>
              <div className="phone1">
                <p className="form">ITni nima maqsadda o'rganmoqchimisiz?</p>{" "}
                <textarea
                  onChange={(e) => setFamilya(e.target.value)}
                  id=""
                ></textarea>
              </div>
              <button onClick={(e) => handleregister(e)}>
                Bepul maslahat olmoqchiman
              </button>
            </form>
            <div className="right-bottom">
              <p className="one">!</p>
              <p className="two">
                Hozirda o'quv markazimiz faqat Toshkent shahrida joylashgan
              </p>
            </div>
          </div>
        </div>
        <div className="foto">
          <img src={foto_absalute} alt="" />
        </div>
      </div>
    </div>
  );
}
