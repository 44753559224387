import React from 'react'
import Div from './Companents/Div/Div'

export default function Home() {
  return (
    <div>
        <Div/>
    </div>
  )
}
